<script>
import moment from 'moment';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import UserService from '@/services/user'

moment.locale('pt-BR');

export default {
  page: {
    title: "Usuários",
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Usuários",
      loading: false,
      users: [],
      
      searchValue: '',
      searchStatus: 'all',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      userSelected: '',
      user: {
        customerRequest: {},
        customerInfo: {}
      },

      showModal: false,
      searchEmptyField: false,

      fields: [
        // { key: 'attendance', sortable: true, label: 'Origem' },
        { key: 'customerName', sortable: true, label: 'Cliente' },
        // { key: 'customerDocumentNumber', sortable: true, label: 'CNPJ' },
        { key: 'name', sortable: true, label: 'Usuário' },
        // { key: 'registerSource', sortable: true, label: 'Origem Cadastro' },
        { key: 'status', sortable: true, label: 'Status' },
        { key: 'phoneNumber', sortable: true, label: 'Telefone' },
        { key: 'city', sortable: true, label: 'Cidade/UF', formatter: (value, key, item) => {
            return `${item.customerCity}/${item.customerState}`;
          }
        },
        // { key: 'customerSaleDate', sortable: true, label: 'Última Compra' },
        { key: 'createdAt', sortable: true, label: 'Data Cadastro', formatter: (value) => {
          return moment(value).format('DD/MM/YYYY HH:mm');
        } },
        // { key: 'email', sortable: true, label: 'E-mail' },
        { key: 'actions', label: 'Ações', class: 'nowrap-col' }
      ],
    }
  },
  computed: {
    rows() {
      return this.users.length;
    }
  },
  methods: {
    loadUsers(showLoading = true, page = 1, term = '', status = '') {
      let loader;

      if (showLoading) {
        loader = this.$loading.show();
      }

      UserService.getB2bUsers(page, term, status)
        .then(res => {
          this.users = res.data.users;
          this.count = res.data.count;
          this.currentCount = res.data.currentCount;
          this.currentPage = res.data.currentPage;
          this.totalPages = res.data.totalPages;
          this.perPage = res.data.perPage;
          this.startIndex = res.data.startIndex;
          this.endIndex = res.data.endIndex;

          if (showLoading) {
            loader.hide();
          }
        })
      .catch(() => {
        if (showLoading) {
          loader.hide();
        }
      })
    },
    onPageChange() {
      window.scrollTo(0,0);
      this.loadUsers(true, this.currentPage, this.searchValue, this.searchStatus);
    },
    search() {
      this.searchEmptyField = false,
      this.loadUsers(true, 1, this.searchValue, this.searchStatus);
    },
    confirmRemoveUser(data) {
      if (data.status === 'pending') {
        return this.$toast.warning('Usuário em análise. Não pode ser removido.')
      }

      this.userSelected = data._id;
      this.$bvModal.show('modal-confirm');
    },
    approveUser() {
      this.$bvModal.hide('modal-approve');

      let loader = this.$loading.show();

      UserService.approveUser(this.userSelected).then(() => {
        this.loadUsers(false, 1, this.searchValue, this.searchStatus);

        loader.hide();
        this.$toast.open('Usuário aprovado')
      }).catch((err) => {
        loader.hide();
        
        if (err && err.response.data.code && err.response.data.code === 'E9999') {
          return this.$toast.warning(err.response.data.msg);
        }

        this.$toast.error('Ocorreu um erro inesperado');
      })
    },
    reproveUser() {
      this.$bvModal.hide('modal-reprove');

      let loader = this.$loading.show();

      UserService.reproveUser(this.userSelected).then(() => {
        this.loadUsers(false, 1, this.searchValue, this.searchStatus);

        loader.hide();
        this.$toast.open('Usuário reprovado')
      }).catch((err) => {
        loader.hide();
        
        if (err && err.response.data.code && err.response.data.code === 'E9999') {
          return this.$toast.warning(err.response.data.msg);
        }

        this.$toast.error('Ocorreu um erro inesperado');
      })
    },
    removeUser() {
      this.$bvModal.hide('modal-confirm');

      let loader = this.$loading.show();

      UserService.removeUser(this.userSelected).then(() => {
        this.loadUsers(false, 1);

        loader.hide();
        this.$toast.open('Usuário removido')
      }).catch(() => {
        loader.hide();
      })
    },
    exportData() {
      let loader = this.$loading.show();

      UserService.exportB2bUsers(this.searchValue).then(res => {
        loader.hide();
        
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'UsuariosB2B.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(() => {
        loader.hide();
      })
    },
    showUserData(item) {
      this.user = item;

      if (item.customer) {
        this.user.customerInfo = item.customerData;
      } else {
        this.user.customerInfo = item.customerRequestData;
      }
    }
  },
  mounted() {
    this.loadUsers();
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <div class="row mb-2 align-items-center">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="mr-2 mb-2">
                  <div class="position-relative search-input">
                    <form @submit.prevent="search" class="align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="searchValue"
                        v-on:keyup="changeSearch"
                        placeholder="Nome do usuário ou razão social do cliente..."
                      />

                      <button type="submit" class="btn btn-primary" v-if="!loading">
                        Pesquisar
                      </button>

                      <a href="javascript:void(0);" class="text-primary" v-if="loading">
                        <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row mb-2">
              <div class="col-lg-12 mb-2">
                <form @submit.prevent="search" class="align-items-center mb-2">
                  <div class="row">
                    <div class="col-lg-6 col-sm-8 ">
                      <div class="form-group">
                        <label>Pesquisar</label>
                        <input
                          type="text"
                          v-model="searchValue"
                          class="form-control"
                          placeholder="Nome do usuário, CNPJ ou razão social do cliente..."
                        />
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-4 ">
                      <div class="form-group">
                        <label>Status</label>
                        <select class="form-control" v-model="searchStatus">
                          <option value="all">TODOS</option>
                          <option value="pending">Aguardando Aprovação</option>
                          <option value="approved">Aprovados</option>
                          <option value="rejected">Rejeitados</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <b-button type="submit" class="btn-rounded " variant="primary">Pesquisar</b-button>

                  <!-- <div class="button-search">
                    <b-button type="submit" variant="primary" v-if="!loading">Pesquisar</b-button>
                    <b-button class="ml-2" variant="outline-primary" @click="exportData" v-if="!loading">Exportar</b-button>
                    <a href="javascript:void(0);" class="text-primary" v-if="loading">
                      <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                    </a>
                  </div> -->
                </form>
              </div>
            </div>

            <div v-if="users && users.length > 0">
              <!-- Table data -->
              <!-- <Customer :users="users" :loadUsers="this.loadUsers" :action="$route.query.action"/> -->

              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="users"
                :fields="fields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(actions)="row">
                  <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>

                    <b-dropdown-item v-b-modal.modal-customer @click="showUserData(row.item)">
                      <i class="fas fa-address-card text-primary mr-2 mt-2"></i>
                      Dados do Cliente
                    </b-dropdown-item>

                    <b-dropdown-item v-b-modal.modal-approve v-if="row.item.status === 'pending'" @click="userSelected = row.item._id">
                      <i class="far fa-thumbs-up text-success mr-2 mt-2"></i> Aprovar Cadastro
                    </b-dropdown-item>

                    <b-dropdown-item v-b-modal.modal-reprove v-if="row.item.status === 'pending'" @click="userSelected = row.item._id">
                      <i class="far fa-thumbs-down text-danger mr-2 mt-2"></i> Rejeitar Cadastro
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <template v-slot:cell(customerName)="row">
                  <p class="mb-0">
                    {{row.item.customerName}}
                    <span class="badge badge-pill badge-soft-success font-size-12 ml-1" v-if="row.item.customerRequest">Novo</span>
                  </p>
                  <p class="text-muted mt-0">CNPJ: {{row.item.customerDocumentNumber}}</p>
                </template>

                <template v-slot:cell(name)="row">
                  <p class="mb-0">{{row.item.name}}</p>
                  <p class="text-muted mt-0">{{row.item.email}}</p>
                </template>

                <template v-slot:cell(status)="row">
                  <span
                    class="badge badge-pill badge-soft-success font-size-12"
                    :class=" {
                      'badge-soft-warning': row.item.status === 'pending',
                      'badge-soft-danger': row.item.status === 'rejected',
                    }"
                  >{{row.item.statusDescription}}</span>
                </template>
              </b-table>

              <div class="row justify-content-md-between align-items-md-center mt-4">
                <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
                <!-- end col-->
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage" 
                      :total-rows="count"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
                <!-- end col-->
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <!-- <div class="row" v-if="loading">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-primary">
            <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
          </a>
        </div>
      </div>
    </div> -->

    <b-modal
      id="modal-approve"
      title="Confirmação"
      title-class="font-18"
      centered
    >
      <p>Confirma a aprovação do cadastro do cliente? Será enviada uma senha provisória para o e-mail cadastrado para liberar o acesso à plataforma B2B.</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-approve')" class="mr-3">Voltar</b-btn>
        <b-btn variant="primary" @click="approveUser">Aprovar</b-btn>
      </div>
    </b-modal>

    <b-modal
      id="modal-reprove"
      title="Confirmação"
      title-class="font-18"
      centered
    >
      <p>Confirma a recusa do cadastro do cliente?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-reprove')" class="mr-3">Voltar</b-btn>
        <b-btn variant="primary" @click="reproveUser">Rejeitar</b-btn>
      </div>
    </b-modal>

    <b-modal
      id="modal-customer"
      title="Dados do Cliente"
      title-class="font-18"
      size="lg"
      centered
    >
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label>CNPJ</label>
            <input type="text" class="form-control" v-model="user.customerInfo.documentNumber" readonly />
          </div>
        </div>

        <div class="col-sm-8">
          <div class="form-group">
            <label>Razão Social</label>
            <input type="text" class="form-control" v-model="user.customerInfo.name" readonly />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label>Telefone</label>
            <input type="text" class="form-control" v-model="user.customerInfo.phoneNumber" readonly />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label>Inscrição Estadual</label>
            <input type="text" class="form-control" v-model="user.customerInfo.stateRegister" readonly />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label>CEP</label>
            <input type="text" class="form-control" v-model="user.customerInfo.zipcode" readonly />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Endereço</label>
            <input type="text" class="form-control" v-model="user.customerInfo.street" readonly />
          </div>
        </div>

        <div class="col-sm-2">
          <div class="form-group">
            <label>Número</label>
            <input type="text" class="form-control" v-model="user.customerInfo.number" readonly />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label>Bairro</label>
            <input type="text" class="form-control" v-model="user.customerInfo.district" readonly />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label>Cidade</label>
            <input type="text" class="form-control" v-model="user.customerInfo.city" readonly />
          </div>
        </div>

        <div class="col-sm-1">
          <div class="form-group">
            <label>UF</label>
            <input type="text" class="form-control" v-model="user.customerInfo.state" readonly />
          </div>
        </div>

        <div class="col-sm-7">
          <div class="form-group">
            <label>Transportadora</label>
            <input type="text" class="form-control" v-model="user.customerInfo.carrierName" readonly />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label>Usuário</label>
            <input type="text" class="form-control" v-model="user.name" readonly />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label>Telefone Contato</label>
            <input type="text" class="form-control" v-model="user.phoneNumber" readonly />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label>E-mail de Acesso</label>
            <input type="text" class="form-control" v-model="user.email" readonly />
          </div>
        </div>
      </div>

      <div slot="modal-footer">
        <b-btn variant="primary" @click="$bvModal.hide('modal-customer')">Fechar</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .search-input form {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 10px;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .form-control[readonly] {
    background-color: #f1f1f1;
  }
</style>